import Vuex from "vuex";
import axios from "axios";
import { API_BASE_URL } from "@/config";
import createPersistedState from "vuex-persistedstate";

// 新增的简历管理模块
const resumeModule = {
  namespaced: true,
  state: {
    resumes: [],
    currentResume: null,
    currentPdfUrl: null,  // 添加这个状态
    unsavedChanges: false, // 标记是否有未保存的更改
  },
  mutations: {
    SET_RESUMES(state, resumes) {
      state.resumes = resumes;
    },
    ADD_RESUME(state, resume) {
      state.resumes.push(resume);
    },
    UPDATE_RESUME(state, updatedResume) {
      const index = state.resumes.findIndex(r => r.id === updatedResume.id);
      if (index !== -1) {
        state.resumes.splice(index, 1, updatedResume);
      }
      if (state.currentResume && state.currentResume.id === updatedResume.id) {
        state.currentResume = updatedResume;
      }
      state.unsavedChanges = true;
    },
    DELETE_RESUME(state, resumeId) {
      state.resumes = state.resumes.filter(r => r.id !== resumeId);
      if (state.currentResume && state.currentResume.id === resumeId) {
        state.currentResume = null;
      }
    },
    UPDATE_RESUME_METADATA(state, updatedResume) {
      const index = state.resumes.findIndex(r => r.id === updatedResume.id);
      if (index !== -1) {
        // 只更新元数据字段
        state.resumes[index] = {
          ...state.resumes[index],
          name: updatedResume.name,
          lastModification: new Date().toISOString()
        };
      }
    },
    SET_UNSAVED_CHANGES(state, status) {
      state.unsavedChanges = status;
    },
    
    // 新增本地更新 mutation
    SET_CURRENT_RESUME_LOCAL(state, resume) {
      state.currentResume = resume;
      state.unsavedChanges = true;
    },
    
    // 添加PDF URL相关的mutation
    SET_CURRENT_PDF_URL(state, url) {
      state.currentPdfUrl = url;
    }
  },
  actions: {
    async fetchResumes({ commit, rootState }) {
      try {
        const response = await axios.get(`${API_BASE_URL}/resume/resumes`, {
          headers: {
            userId: rootState.userID,
          },
        });
        commit('SET_RESUMES', response.data.resumes);
      } catch (error) {
        console.error('Failed to fetch resumes:', error);
        throw error;
      }
    },
    async fetchResumeById({ commit }, resumeId) {
      try {
        const response = await axios.get(`${API_BASE_URL}/resume/download`, {
          params: { resumeId },
        });
        commit('SET_CURRENT_RESUME_LOCAL', response.data);
      } catch (error) {
        console.error('Failed to fetch resume:', error);
        throw error;
      }
    },
    async createResume({ commit }, resumeData) {
      try {
        const response = await axios.post(`${API_BASE_URL}/resume/create`, resumeData);
        commit('ADD_RESUME', response.data);
      } catch (error) {
        console.error('Failed to create resume:', error);
        throw error;
      }
    },
    async updateResume({ commit }, resumeData) {
      try {
        await axios.post(`${API_BASE_URL}/resume/update`, resumeData);
        
        commit('SET_CURRENT_RESUME_LOCAL', resumeData);
        commit('SET_UNSAVED_CHANGES', false);
      } catch (error) {
        console.error('Failed to update resume:', error);
        throw error;
      }
    },
    
    // 新增本地更新 action
    updateResumeLocal({ commit }, resumeData) {
      commit('SET_CURRENT_RESUME_LOCAL', resumeData);
      commit('UPDATE_RESUME_METADATA', resumeData);
    },
    
    // 触发云端保存
    async saveResume({ state }) {
      if (!state.unsavedChanges || !state.currentResume) return;
      try {
        await this.dispatch('resume/updateResume', state.currentResume);
        console.log('Resume saved successfully');
      } catch (error) {
        console.error('Failed to save resume:', error);
      }
    },
    
    async deleteResume({ commit }, resumeId) {
      try {
        await axios.delete(`${API_BASE_URL}/resume/delete_resume`, {
          data: { id: resumeId },
          headers: {
            'Content-Type': 'application/json',
          },
        });
        commit('DELETE_RESUME', resumeId);
      } catch (error) {
        console.error('Failed to delete resume:', error);
        throw error;
      }
    },
  },
};

export default new Vuex.Store({
  state: {
    userLoggedIn: false,
    userID: null,
    userStatus: 0, // 0: 普通用户, 1: 开发团队, 2: 合作学校
    isNewUser: false,
    invitationCode: "",
  },
  getters: {
    isUserLoggedIn(state) {
      return state.userLoggedIn;
    },
    getUserID(state) {
      return state.userID;
    },
    getUserStatus(state) {
      return state.userStatus;
    },
    isNewUser(state) {
      return state.isNewUser;
    },
    getInvitationCode(state) {
      return state.invitationCode;
    },
  },
  mutations: {
    SET_USER_LOGGED_IN(state, status) {
      state.userLoggedIn = status;
    },
    SET_USER_ID(state, userID) {
      state.userID = userID;
    },
    SET_USER_STATUS(state, status) {
      state.userStatus = status;
    },
    SET_IS_NEW_USER(state, isNewUser) {
      state.isNewUser = isNewUser;
    },
    SET_INVITATION_CODE(state, invitationCode) {
      state.invitationCode = invitationCode;
    },
    LOGOUT_USER(state) {
      state.userLoggedIn = false;
      state.userID = null;
      state.userStatus = 0;
      state.isNewUser = false;
      state.invitationCode = "";
      state.resume = {};
    },
  },
  actions: {
    async loginUser({ commit }, { email, password }) {
      try {
        const response = await axios.post(`${API_BASE_URL}/user/login`, {
          email,
          password,
        });
        commit("SET_USER_LOGGED_IN", true);
        commit("SET_USER_ID", response.data.userID);
        commit("SET_USER_STATUS", response.data.userStatus);
        commit("SET_IS_NEW_USER", response.data.isNewUser);
        commit("SET_INVITATION_CODE", response.data.invitationCode);
      } catch (error) {
        console.error("Login failed:", error);
        throw error;
      }
    },
    logoutUser({ commit }) {
      commit("LOGOUT_USER");
    },
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage, // 将状态保存到 sessionStorage
    }),
  ],
  modules: {
    resume: resumeModule,
  },
});
