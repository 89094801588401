<template>
  <div id="app">
    <!-- 页眉 -->
    <HeaderComponent />

    <!-- 路由视图，用于显示不同的页面内容 -->
    <div class="content">
      <router-view />
    </div>

    <!-- 页脚 -->
    <FooterComponent />

    <!-- 全局的 AlertComponent -->
    <AlertComponent ref="globalAlert" />
  </div>
</template>

<script>
import HeaderComponent from "./components/HeaderComponent.vue";
import FooterComponent from "./components/FooterComponent.vue";
import AlertComponent from "./components/AlertComponent.vue";

export default {
  name: "App",
  components: {
    HeaderComponent,
    FooterComponent,
    AlertComponent,
  },
  mounted() {
    // 重写全局的 alert 方法，使它调用自定义的 AlertComponent
    window.alert = (message) => {
      this.$refs.globalAlert.open(message);
    };
  },
};
</script>

<style>
/* 确保整个页面占满窗口 */
html,
body,
#app {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* 使用 flexbox 布局确保页眉在顶部，页脚在底部，内容占据剩余空间 */
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1; /* 让内容部分占据剩余空间 */
  padding: 20px; /* 添加一些内边距 */
}

/* 全局的基础样式 */
body {
  font-family: Arial, sans-serif;
}
</style>
